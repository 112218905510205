import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/common/layout/Layout";
import Faq from "../contents/md/faq.mdx";
import { Container, Section } from "../components/common/layout/Containers";
import MdxContainer from "../components/common/layout/MdxLayout";
import SubscribeForm from "../components/sections/SubscribeForm";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Section mdxType="Section">
    <Container mdxType="Container">
      <MdxContainer mdxType="MdxContainer">
        <Faq mdxType="Faq" />
      </MdxContainer>
    </Container>
  </Section>
  <SubscribeForm bgLight mdxType="SubscribeForm" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      